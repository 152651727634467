export const journey = {
  title: 'Du concept a la réalité : Créé votre site en 3 étapes',
  description:
    "Chez Nailam, nous comprenons vos priorités. C'est pourquoi nous avons simplifié le processus de création de site web en 3 étapes, pour que vous puissiez vous concentrer pleinement sur le développement de votre entreprise.",
  journey: [
    {
      title: 'Rencontre initiale',
      description:
        "Ensemble, nous explorons vos besoins et objectifs lors d'un échange pour établir les bases de votre projet.",
    },
    {
      title: 'Conception sur Mesure',
      description:
        'Notre équipe talentueuse crée une maquette personnalisée de votre site web, reflétant parfaitement votre marque et vos aspirations.',
    },
    {
      title: 'Lancement Réussi',
      description:
        'Une fois la maquette approuvée, nous procédons à la construction et à la mise en ligne de votre site, prêt à impressionner vos visiteurs dès le premier clic ?',
    },
  ],
};
