import {
  BookOpenCheck,
  CalendarCog,
  Cog,
  Handshake,
  Headset,
  LayoutPanelTop,
  PaintRoller,
  StickyNote,
  View,
} from 'lucide-react';

export const offers = {
  title: 'Trouvez votre Solution au Meilleur Prix',
  description:
    "Chez Nailam, nous croyons en la transparence et l'accessibilité, offrant des solutions web de haute qualité à des tarifs compétitifs pour répondre à tous les budgets.",
  offers: [
    {
      name: ' Offre Single Page',
      price: '900€',
      description:
        'Cette offre est parfaite pour les TPE / PME entreprises ou les professionnels souhaitant établir une présence en ligne impressionnante avec une page unique riche en fonctionnalités.',
      content: [
        { name: 'Design personnalisé ', icon: <PaintRoller /> },
        { name: 'Optimisation SEO ', icon: <Cog /> },
        { name: 'Support technique ', icon: <Headset /> },
        { name: 'Mises à jour à vie', icon: <CalendarCog /> },
        { name: "Jusqu'a 8 sections ", icon: <LayoutPanelTop /> },
      ],
    },
    {
      name: 'Offre Multi Page',
      price: '1990€',
      description:
        "Idéale pour les entreprises nécessitant plus d'espace pour détailler leurs services et attirer différents segments de clientèle avec plusieurs pages.",
      content: [
        { name: 'Design personnalisé', icon: <PaintRoller /> },
        { name: 'Optimisation SEO ', icon: <Cog /> },
        { name: 'Support technique ', icon: <Headset /> },
        { name: 'Mises à jour à vie', icon: <CalendarCog /> },
        { name: "Jusqu'a 8 sections", icon: <LayoutPanelTop /> },
        { name: '2 page supplementaire', icon: <StickyNote /> },
      ],
    },
    {
      name: 'Offre Flexible',
      price: 'Flexible',
      description:
        'Pour les projets nécessitant une approche sur mesure ou pour les clients ayant des besoins spécifiques qui ne cadrent pas avec les offres standard.',
      content: [
        { name: 'Consultation gratuite', icon: <View /> },
        { name: 'Évaluation et proposition', icon: <BookOpenCheck /> },
        { name: 'Devis personalisé', icon: <Handshake /> },
      ],
    },
  ],
};
