import { Minus, Plus } from 'lucide-react';
import { FC, useEffect, useRef, useState } from 'react';
import { faq } from '../assets/content/FAQ';
import faqImg from '../assets/faq.webp';
import { useRefsStore } from '../core/store/useRefsStore';

export const FAQ: FC = () => {
  const ref = useRef(null);

  const setFaqRef = useRefsStore((state) => state.setFaqRef);
  const [isOpen, setIsOpen] = useState(faq.faqData.map(() => false));
  const [toOpenIndex, setToOpenIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    if (isOpen[index]) {
      setIsOpen((prev) =>
        prev.map((_, i) => (i === index ? !prev[i] : prev[i]))
      );
    } else {
      const currentlyOpenIndex = isOpen.findIndex((open) => open);
      if (currentlyOpenIndex !== -1) {
        setIsOpen((prev) =>
          prev.map((_, i) => (i === currentlyOpenIndex ? false : prev[i]))
        );
        setToOpenIndex(index);
      } else {
        setIsOpen((prev) => prev.map((_, i) => (i === index ? true : prev[i])));
      }
    }
  };

  const handleTransitionEnd = (index: number) => {
    if (toOpenIndex !== null && !isOpen[index]) {
      setIsOpen((prev) =>
        prev.map((_, i) => (i === toOpenIndex ? true : prev[i]))
      );
      setToOpenIndex(null);
    }
  };

  useEffect(() => {
    if (ref.current) {
      setFaqRef(ref);
    }
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        entries[0].target.classList.add('animate-fadeIn');
        observer.unobserve(ref.current!);
      }
    });
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, [setFaqRef]);

  return (
    <section
      ref={ref}
      className="flex justify-center xl:px-40 lg:px-20 sm:px-10 px-5 mt-20 py-28 bg-[#f8f8f8] opacity-0 transition-all duration-[1500ms] ease-in-out"
    >
      <div className="w-[1500px]">
        <h2 className="uppercase font-bold md:text-3xl text-2xl leading-tight overflow-y-hidden text-center">
          {faq.title}
        </h2>
        <p className="text-center mt-3 pb-4">{faq.description}</p>
        <div className="flex items-center justify-center gap-14 mt-12 h-[770px] xl:h-[550px]">
          <div className="w-fit flex flex-col gap-5">
            {faq.faqData.map((faq, idx) => (
              <div
                key={idx}
                onClick={() => handleClick(idx)}
                className={`border p-6 w-full rounded ${
                  isOpen[idx] && 'bg-black text-white'
                } cursor-pointer`}
              >
                <div className="flex justify-between">
                  <p>{faq.question}</p>
                  {isOpen[idx] ? <Minus size={20} /> : <Plus size={20} />}
                </div>
                <div
                  className={`transition-max-height duration-[400ms] ease-in-out overflow-hidden ${
                    isOpen[idx] ? 'max-h-40' : 'max-h-0'
                  }`}
                  onTransitionEnd={() => handleTransitionEnd(idx)}
                >
                  <p className="mt-4">{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
          <img
            src={faqImg}
            alt="foire aux questions"
            className="w-[500px] max-lg:hidden rounded-xl shadow-md"
          />
        </div>
      </div>
    </section>
  );
};
