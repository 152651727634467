import { FC, useEffect, useRef } from 'react';
import { ButtonContact } from './ui/ButtonContact';
// import { useRefsStore } from '../core/store/useRefsStore';
import heroImg from '../assets/hero.webp';
import { useRefsStore } from '../core/store/useRefsStore';

export const Hero: FC = () => {
  const ref = useRef(null);
  const setHomeRef = useRefsStore((state) => state.setHomeRef);

  useEffect(() => {
    if (ref.current) {
      setHomeRef(ref);
    }
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        entries[0].target.classList.add('animate-fadeIn');
        observer.unobserve(ref.current!);
      }
    });
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, [setHomeRef]);

  return (
    <section
      className="xl:px-40 lg:px-20 sm:px-10 px-5 flex justify-center mb-36 opacity-0 transition-all duration-[1500ms] ease-in-out"
      ref={ref}
    >
      <div className="flex justify-between lg:items-center w-[1500px] lg:pt-28 max-lg:flex-col max-lg:pt-24 ">
        <div className="flex flex-col">
          <h1 className="uppercase font-bold md:text-5xl text-3xl overflow-y-hidden">
            des créations web
            <br />
            entièrement personalisées.
          </h1>
          <p className="text-[#353535] max-w-[530px] sm:mt-5 sm:mb-10 mb-5 mt-2">
            Nailam, l'agence web qui crée des sites web sur-mesure.
          </p>
          <div className="w-fit">
            <ButtonContact content="Je souhaite créer mon site" />
          </div>
          {/* <a href="https://google.com" target="_blank">
            <img
              alt="google business link"
              src="/src/assets/google.webp"
              className="w-44"
            />
          </a> */}
        </div>
        <img
          src={heroImg}
          className="lg:w-[40%] sm:w-3/4 w-4/8 max-lg:mt-16 rounded-xl shadow-xl"
          alt="page d'accueil"
          width={600}
          height={600}
        />
      </div>
    </section>
  );
};
