import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { send } from 'emailjs-com';
import { estimateFormShema } from '../core/schema/EstimateForm';

type Inputs = z.infer<typeof estimateFormShema>;

type Props = {
  title: string;
  description: string;
};

export const EstimateForm: FC<Props> = ({ title, description }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(estimateFormShema),
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    send('service_vfsqagg', 'template_0javnvu', data, 'tkPyw-fiGIex5nVuj');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      <h2 className="uppercase font-bold md:text-4xl text-3xl leading-tight mb-4 overflow-y-hidden">
        {title}
      </h2>
      <p className="text-left mb-12">{description}</p>
      <div className="flex gap-8 mb-8 max-xl:flex-col">
        <div className="flex flex-col w-full">
          <label htmlFor="name" className="mb-2">
            Nom
          </label>
          <input
            autoComplete="off"
            id="name"
            placeholder="John Smith"
            className="p-2 rounded outline-none bg-[#f0f0f0] w-full"
            {...register('name', { required: true })}
          />
          {errors.name && (
            <span className="text-red-500 pt-2">{errors.name.message}</span>
          )}
        </div>
        <div className="flex flex-col w-full">
          <label htmlFor="email" className="mb-2">
            Email
          </label>
          <input
            autoComplete="off"
            id="email"
            placeholder="hello@example.com"
            className="p-2 rounded outline-none bg-[#f0f0f0] w-full"
            {...register('email', { required: true })}
          />
          {errors.email && (
            <span className="text-red-500 pt-2">{errors.email.message}</span>
          )}
        </div>
      </div>

      <div className="flex gap-8 mb-8 max-xl:flex-col">
        <div className="flex flex-col w-full">
          <label htmlFor="phone" className="mb-2">
            Téléphone
          </label>
          <input
            autoComplete="off"
            id="phone"
            placeholder="06 ..."
            className="p-2 rounded outline-none bg-[#f0f0f0] w-full"
            {...register('phone', { required: true })}
          />
          {errors.phone && (
            <span className="text-red-500 pt-2">{errors.phone.message}</span>
          )}
        </div>
        <div className="flex flex-col w-full">
          <label htmlFor="howYouKnowUs" className="mb-2">
            Comment nous avez-vous connu ?
          </label>
          <select
            {...register('howYouKnowUs')}
            aria-label="Comment nous avez-vous connu ?"
            className="p-3 rounded outline-none bg-[#f0f0f0] w-full"
          >
            <option value="Choisir une option">Choisir une option</option>
            <option value="Recherche sur internet">
              Recherche sur internet
            </option>
            <option value="Réseaux sociaux">Réseaux sociaux</option>
            <option value="Recommandation">Recommandation</option>
            <option value="Publicité en ligne">Publicité en ligne</option>
            <option value="Blog/Article">Blog/Article</option>
            <option value="Événements/Conférences">
              Événements/Conférences
            </option>
            <option value="Autre">Autre</option>
          </select>
        </div>
      </div>

      <div className="flex flex-col">
        <label htmlFor="message" className="mb-2">
          Message
        </label>
        <textarea
          id="message"
          placeholder="Entrez votre message ici.."
          className="p-2 rounded outline-none w-full min-h-[150px] max-h-[250px] bg-[#f0f0f0]"
          {...register('message')}
        />
      </div>
      <div className="flex justify-center">
        <input
          type="submit"
          className="my-6 bg-black text-white px-5 py-3 rounded-full cursor-pointer"
        />
      </div>
    </form>
  );
};
