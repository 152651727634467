import { Outlet, useLocation } from 'react-router-dom';
import { Header } from '../Header';
import { FC, useEffect } from 'react';
import { Footer } from '../Footer';

export const Layout: FC = () => {
  const location = useLocation();

  // pour que quand on change de page on arrive tout en haut
  useEffect(() => {
    if (location.pathname !== '/') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
