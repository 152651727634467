import { FC } from 'react';
import { Benefits } from '../components/Benefits';
import { Hero } from '../components/Hero';
import { Offers } from '../components/Offers';
import { Journey } from '../components/Journey';
import { useDocumentTitle } from 'usehooks-ts';

export const Home: FC = () => {
  useDocumentTitle("Nailam - l'agence web qui crée des sites web sur-mesure.");

  return (
    <main>
      <Hero />
      <Benefits />
      <Journey />
      <Offers />
      {/* <Reviews /> */}
    </main>
  );
};
