import { FC } from 'react';

type Props = {
  title: string;
  img: string;
};

export const PageCover: FC<Props> = ({ title, img }) => {
  return (
    <div className="relative w-full h-80 overflow-hidden">
      <img
        src={img}
        alt={title}
        className="w-full h-full object-cover object-center"
      />
      <h1 className="text-white text-4xl font-bold text-center absolute inset-0 flex items-center justify-center uppercase text-stroke bg-[rgba(0,0,0,0.5)]">
        {title}
      </h1>
      <span className="absolute border-b-[80px] border-b-white border-l-[100vw] border-l-transparent block bottom-0" />
    </div>
  );
};
