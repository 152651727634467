import { FC } from 'react';
import { contact } from '../assets/content/Contact';

export const ContactInfos: FC = () => {
  return (
    <div className="bg-black rounded w-[100dvw] sm:w-11/12 md:w-4/5 lg:w-96 lg:h-full text-white px-9 py-14 ">
      <h2 className="uppercase font-bold md:text-3xl text-2xl leading-tight overflow-y-hidden">
        {contact.info.title}
      </h2>
      <span className="block h-px w-full bg-gray-300 rounded-full mt-6" />
      {contact.info.infos.map((info) => (
        <div key={info.title} className="flex gap-6 mt-7">
          <div className="rounded-full p-3 bg-white">{info.icon}</div>
          <div>
            <p className="uppercase">{info.title}</p>
            {info.title === 'notre email' ? (
              <a href={`mailto:contact@nailam.fr`}>
                <p>{info.content}</p>
              </a>
            ) : (
              <a href={`tel:0624094870`}>
                <p>{info.content}</p>
              </a>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
