import { z } from 'zod';

export const estimateFormShema = z.object({
  name: z
    .string()
    .regex(
      /^[A-Za-zÀ-ÿ\-'\s]+$/,
      'Le nom ne peut contenir que des lettres, des espaces, des tirets ou des apostrophes'
    )
    .min(1, 'Le nom est requis'),
  email: z.string().email("L'adresse email n'est pas valide"),
  phone: z
    .string()
    .regex(/^\d{10}$/, 'Le téléphone doit être un numéro valide'),
  message: z.string(),
  howYouKnowUs: z.enum([
    'Choisir une option',
    'Recherche sur internet',
    'Réseaux sociaux',
    'Recommandation',
    'Publicité en ligne',
    'Blog/Article',
    'Événements/Conférences',
    'Autre',
  ]),
});
