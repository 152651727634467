import { Instagram } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useRefsStore } from '../core/store/useRefsStore';

export const Footer: FC = () => {
  // const location = useLocation();
  // const isDarkMode = location.pathname !== '/';
  const isDarkMode = true;
  const { faqRef, homeRef, offersRef, reviewsRef } = useRefsStore(
    (state) => state
  );
  const navigate = useNavigate();
  const [scrollToSection, setScrollToSection] = useState<string | null>(null);

  useEffect(() => {
    if (scrollToSection) {
      setTimeout(() => {
        if (scrollToSection === 'offers' && offersRef?.current) {
          offersRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (scrollToSection === 'reviews' && reviewsRef?.current) {
          reviewsRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (scrollToSection === 'home' && homeRef?.current) {
          homeRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (scrollToSection === 'faq' && faqRef?.current) {
          faqRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setScrollToSection(null);
      }, 100);
    }
  }, [scrollToSection, offersRef, reviewsRef, homeRef, faqRef]);

  const handleNavigation = (section: string) => {
    setScrollToSection(section);
    navigate(`${section === 'faq' ? '/contact' : '/'}`);
  };

  return (
    <section
      className={`flex justify-center xl:px-40 lg:px-20 sm:px-10 px-5 ${
        isDarkMode ? 'bg-[#101010] text-white' : 'bg-white text-black'
      }`}
    >
      <footer className="w-[1500px]">
        <div className="flex md:items-center justify-between mb-5 max-md:flex-col pt-10">
          <h2
            className="font-bold text-4xl leading-tight tracking-tighter cursor-pointer"
            onClick={() => handleNavigation('home')}
          >
            nailam
          </h2>
          <p className="max-md:mt-5">
            Nailam, l'agence web qui crée des sites web sur-mesure.
          </p>
        </div>
        <span className="bg-gray-300 h-px block mb-8 rounded-full mt-7" />
        <div className="flex gap-10 sm:gap-20">
          <div className="flex flex-col gap-2">
            <p className="font-bold pb-3">A Propos</p>
            {/* <NavLink
              to="/realisations"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Réalisations
            </NavLink> */}
            <p
              className="cursor-pointer"
              onClick={() => handleNavigation('offers')}
            >
              Nos Offres
            </p>
            <NavLink to="/contact" onClick={() => handleNavigation('faq')}>
              FAQ
            </NavLink>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold pb-3">Liens Utiles</p>
            {/* <p
              className="cursor-pointer"
              onClick={() => handleNavigation('reviews')}
            >
              Avis Clients
            </p> */}
            <NavLink
              to="/contact"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Contact
            </NavLink>
            {/* <NavLink
              to="/blog"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Blog
            </NavLink> */}
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold pb-3">Pages légales</p>
            <NavLink
              to="/mentions-légales"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Mentions légales
            </NavLink>
          </div>
        </div>
        <span className="bg-gray-300 h-px block mb-8 rounded-full mt-7" />
        <div className="flex justify-between mb-5">
          <p>Copyright © 2024 nailam </p>
          <div className="flex gap-2">
            <a
              href="https://www.instagram.com/agence_nailam/"
              target="_blank"
              aria-label="Instagram de Nailam Agence"
            >
              <Instagram />
            </a>
            {/* <Linkedin /> */}
          </div>
        </div>
      </footer>
    </section>
  );
};
