import { FC, useEffect, useRef } from 'react';
import { ButtonContact } from './ui/ButtonContact';
import { journey } from '../assets/content/Journey';
import journeyImg from '../assets/journey.webp';

export const Journey: FC = () => {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        entries[0].target.classList.add('animate-fadeIn');
        observer.unobserve(ref.current!);
      }
    });
    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return (
    <section
      ref={ref}
      className="flex justify-center bg-[#f6f6f6] xl:px-40 lg:px-20 sm:px-10 px-5 pt-20 opacity-0 transition-all duration-[1500ms] ease-in-out"
    >
      <div className=" max-w-[1500px]">
        <h2 className="uppercase font-bold md:text-4xl text-3xl leading-tight mb-4 overflow-y-hidden">
          {journey.title}
        </h2>
        <p>{journey.description}</p>
        <div className="flex justify-around items-center mt-12 mb-24">
          <div className="w-full lg:w-[44%]">
            <div className="mb-7">
              {journey.journey.map((etape, idx) => {
                return (
                  <div key={etape.title}>
                    <div className="flex items-center gap-5">
                      <div className="h-4 w-4 bg-[#0B0B0B] rounded-full"></div>
                      <h3 className="uppercase font-medium text-l">
                        {etape.title}
                      </h3>
                    </div>
                    <div className="flex gap-5">
                      {idx !== journey.journey.length - 1 ? (
                        <div className="min-h-full  ml-[7px] mr-[7px] border-l-2 border-dashed border-gray-500"></div>
                      ) : (
                        <div className="h-24 w-[16px]"></div>
                      )}
                      <p className="w-full mb-4 font-light">
                        {etape.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <ButtonContact content="Commencer maintenant" />
          </div>
          <img
            className="w-[38%] max-lg:hidden rounded-xl shadow-xl"
            src={journeyImg}
            alt="Section parcours client"
            loading="lazy"
            decoding="async"
            width={570}
            height={570}
          />
        </div>
      </div>
    </section>
  );
};
