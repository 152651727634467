import { FC } from 'react';
import { ArrowUpRight } from 'lucide-react';

type Props = {
  content: string;
  isDark?: boolean;
};

export const ButtonContact: FC<Props> = ({ content, isDark = true }) => {
  const buttonStyle = isDark
    ? 'bg-[#0B0B0B] text-white'
    : 'bg-white text-black border border-gray-300';

  return (
    <a href="https://cal.com/nailam/visio-30min" target="_blank">
      <button
        className={`group flex justify-center rounded-full py-2 transition duration-300 ease-in-out ${buttonStyle} w-fit`}
      >
        <div className="flex items-center gap-2 overflow-y-hidden px-4">
          <p className="text-base">{content}</p>
          <div
            className={`rounded-full p-2 transform transition-transform duration-300 group-hover:translate-x-2 group-hover:rotate-45 ${
              isDark ? 'bg-white' : 'bg-[#0B0B0B]'
            }`}
          >
            <ArrowUpRight size="16" color={isDark ? 'black' : 'white'} />
          </div>
        </div>
      </button>
    </a>
  );
};
