import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useRefsStore } from '../../core/store/useRefsStore';
import { FC, useEffect, useState } from 'react';

type Props = {
  isHeader: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};

export const Navigation: FC<Props> = ({ isHeader, isOpen, setIsOpen }) => {
  const { offersRef, reviewsRef, homeRef } = useRefsStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollToSection, setScrollToSection] = useState<string | null>(null);

  useEffect(() => {
    if (scrollToSection) {
      setTimeout(() => {
        if (scrollToSection === 'offers' && offersRef?.current) {
          offersRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (scrollToSection === 'reviews' && reviewsRef?.current) {
          reviewsRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (scrollToSection === 'home' && homeRef?.current) {
          homeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setScrollToSection(null);
      }, 100);
    }
  }, [scrollToSection, offersRef, reviewsRef, homeRef]);

  const handleNavigation = (section: string) => {
    setScrollToSection(section);
    navigate('/');
  };

  return (
    <>
      {isHeader && (
        <h2
          className="font-bold text-4xl leading-tight tracking-tighter cursor-pointer"
          onClick={() => handleNavigation('home')}
        >
          nailam
        </h2>
      )}
      <nav className={`${isHeader && 'max-lg:hidden'}`}>
        <ul
          className={`${isHeader === false && 'flex flex-col text-xl'} ${
            isHeader && 'flex gap-9 font-medium max-xl:text-sm'
          }`}
        >
          {/* <li
            className={`${isHeader === false && 'font-medium p-5 text-center'}`}
          >
            <NavLink
              to="/realisations"
              onClick={() => {
                setIsOpen && setIsOpen(!isOpen);
                location.pathname === '/realisations' &&
                  window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Réalisations
            </NavLink>
          </li> */}
          {/* <li
            className={`${isHeader === false && 'font-medium p-5 text-center'}`}
          >
            <NavLink
              to="/blog"
              onClick={() => {
                setIsOpen && setIsOpen(!isOpen);
                location.pathname === '/blog' &&
                  window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Blog
            </NavLink>
          </li> */}
          <li
            onClick={() => {
              handleNavigation('offers');
              setIsOpen && setIsOpen(!isOpen);
            }}
            className={`cursor-pointer ${
              isHeader === false && 'font-medium p-5 text-center'
            }`}
          >
            Nos offres
          </li>
          {/* <li
            onClick={() => {
              handleNavigation('reviews');
              setIsOpen && setIsOpen(!isOpen);
            }}
            className={`cursor-pointer ${
              isHeader === false && 'font-medium p-5 text-center'
            }`}
          >
            Avis clients
          </li> */}
          <li
            className={`${isHeader === false && 'font-medium p-5 text-center'}`}
          >
            <NavLink
              to="/contact"
              onClick={() => {
                setIsOpen && setIsOpen(!isOpen);
                location.pathname === '/contact' &&
                  window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Contact
            </NavLink>
          </li>
          <li
            className={`${
              isHeader === false && 'font-medium p-5 mt-3 text-center'
            }`}
          >
            <a
              href="https://cal.com/nailam/visio-30min"
              className="px-10 py-4 bg-[#0B0B0B] text-white rounded-full lg:hidden "
              target="_blank"
            >
              Prendre rendez-vous
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};
