import { FC, useEffect, useRef } from 'react';
import { PageCover } from '../components/PageCover';
import { ContactInfos } from '../components/ContactInfos';
import { FAQ } from '../components/FAQ';
import { EstimateForm } from '../components/EstimateForm';
import contactImg from '../assets/contact.webp';
import { useDocumentTitle } from 'usehooks-ts';

export const Contact: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  useDocumentTitle('nailam - Contact');

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        entries[0].target.classList.add('animate-fadeIn');
        observer.unobserve(ref.current!);
      }
    });
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return (
    <main className="overflow-y-hidden">
      <PageCover title="parlez-nous de votre projet" img={contactImg} />
      <div className="flex justify-center xl:px-40 lg:px-20 sm:px-10 px-5">
        <section
          className="max-w-[1500px] flex max-lg:flex-col gap-10 mt-24 max-lg:items-center opacity-0 transition-all duration-[1500ms] ease-in-out"
          ref={ref}
        >
          <ContactInfos />
          <div className="bg-[#f8f8f8] rounded w-[100dvw] sm:w-11/12 md:w-4/5 lg:w-fit px-9 pt-10 text-black ">
            <EstimateForm
              title="Notre équipe est toujours à l'écoute"
              description="Bienvenue sur notre formulaire de contact. N'hésitez pas à nous écrire pour obtenir des informations supplémentaires sur nos services de création de sites web et sur la manière dont nous pouvons répondre à vos besoins."
            />
          </div>
        </section>
      </div>
      <FAQ />
    </main>
  );
};
