import { Dot } from 'lucide-react';
import { offers } from '../assets/content/Offers';
import { useRefsStore } from '../core/store/useRefsStore';
import { FC, useEffect, useRef } from 'react';

export const Offers: FC = () => {
  const ref = useRef(null);
  const setOffersRef = useRefsStore((state) => state.setOffersRef);

  useEffect(() => {
    if (ref.current) {
      setOffersRef(ref);
    }
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        entries[0].target.classList.add('animate-fadeIn');
        observer.unobserve(ref.current!);
      }
    });
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, [setOffersRef]);

  return (
    <section
      className="flex justify-center xl:px-40 lg:px-20 sm:px-10 px-5 pt-24 pb-24 opacity-0 transition-all duration-[1500ms] ease-in-out"
      ref={ref}
    >
      <div className="max-w-[1500px]">
        <h2 className="uppercase font-bold md:text-4xl text-3xl leading-tight mb-4 overflow-y-hidden">
          {offers.title}
        </h2>
        <p>{offers.description}</p>
        <div className="grid grid-cols-1 lg:grid-cols-3 max-lg:gap-10 mt-11 w-full">
          {offers.offers.map((offer) => {
            return (
              <div key={offer.name} className="flex justify-center text-white">
                <div className="min-h-[540px] w-[90%] max-w-[450px] rounded-2xl bg-[#101010] px-7 py-10">
                  <h3 className="uppercase font-medium text-xl">
                    {offer.name}
                  </h3>
                  <p className="pt-4 lg:h-52 h-fit xl:h-48 2xl:h-36 mb-5 text-gray-300">
                    {offer.description}
                  </p>
                  <span className="block bg-[#D9D9D9] h-px w-full rounded-full" />
                  <p className="text-xl mt-2">À partir de</p>
                  <p className="text-4xl min-h-14">{offer.price}</p>
                  <span className="block bg-[#D9D9D9] h-px w-full rounded-full mb-5" />
                  <ul className="h-fit lg:h-[170px]">
                    {offer.content.map((item) => (
                      <li key={item.name} className="flex items-center">
                        {/* {item.icon} */}
                        <Dot />
                        {item.name}
                      </li>
                    ))}
                  </ul>
                  <div className="w-full flex justify-center mt-5">
                    <a
                      href="https://cal.com/nailam/visio-30min"
                      target="_blank"
                      className="text-[#0B0B0B] bg-white py-3 rounded-full text-center font-medium w-full"
                    >
                      Commencer maintenant
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
