import { FC } from 'react';
import { BurgerMenu } from './ui/BurgerMenu';
import { Navigation } from './ui/Navigation';

export const Header: FC = () => {
  return (
    <section className="sticky top-0 flex justify-center py-4 border-b bg-white z-50 xl:px-40 lg:px-20 sm:px-10 px-5">
      <div className="flex items-center justify-between w-[1500px]">
        <Navigation isHeader={true} />
        <a
          href="https://cal.com/nailam/visio-30min"
          className="px-6 py-3 bg-[#0B0B0B] text-white rounded-full max-lg:hidden max-xl:text-sm"
          target="_blank"
        >
          Prendre rendez-vous
        </a>
        <div className="lg:hidden">
          <BurgerMenu />
        </div>
      </div>
    </section>
  );
};
