export const faq = {
  title: 'F.A.Q',
  description:
    'Bienvenue dans notre foire aux questions ! Trouvez ici les réponses aux questions les plus courantes sur nos services de création de sites web et découvrez comment nous pouvons vous aider à concrétiser votre projet en ligne.',
  faqData: [
    {
      question: 'En combien de temps mon site sera livré ?',
      answer: 'Une fois le contrat signé, votre site sera livré sous 14 jours.',
    },
    {
      question: 'Mon site sera-t-il compatible avec les mobiles et tablettes ?',
      answer:
        'Oui, tous nos sites sont conçus pour garantir une expérience utilisateur optimale sur tous les appareils.',
    },
    {
      question:
        'Mon site sera-t-il optimisé pour les moteurs de recherche (SEO) ?',
      answer:
        'Oui, nous intégrons les meilleures pratiques SEO dès la conception et le développement de votre site pour améliorer sa visibilité sur les moteurs de recherche.',
    },
    {
      question: 'Dois-je fournir les contenus (textes, images) pour mon site ?',
      answer:
        "Nous pouvons utiliser les contenus que vous fournissez. Cependant, si vous avez besoin d'aide, notre équipe peut créer des textes pour vous et intégrer des images pour enrichir votre site web.",
    },
    {
      question: 'Puis-je demander des modifications une fois le site terminé ?',
      answer:
        'Oui, nous incluons une série illimitée de révisions dans notre offre initiale.',
    },
  ],
};
