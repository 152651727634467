import { create } from 'zustand';

type InitialState = {
  offersRef: React.RefObject<HTMLLIElement> | null;
  reviewsRef: React.RefObject<HTMLLIElement> | null;
  homeRef: React.RefObject<HTMLLIElement> | null;
  faqRef: React.RefObject<HTMLLIElement> | null;
};

type Actions = {
  setReviewsRef: (ref: React.RefObject<HTMLLIElement>) => void;
  setOffersRef: (ref: React.RefObject<HTMLLIElement>) => void;
  setHomeRef: (ref: React.RefObject<HTMLLIElement>) => void;
  setFaqRef: (ref: React.RefObject<HTMLLIElement>) => void;
};

const initialState: InitialState = {
  offersRef: null,
  reviewsRef: null,
  homeRef: null,
  faqRef: null,
};

export const useRefsStore = create<InitialState & Actions>((set) => ({
  ...initialState,
  setReviewsRef: (reviewsRef: React.RefObject<HTMLLIElement>) => {
    set({ reviewsRef });
  },
  setOffersRef: (offersRef: React.RefObject<HTMLLIElement>) => {
    set({ offersRef });
  },
  setHomeRef: (homeRef: React.RefObject<HTMLLIElement>) => {
    set({ homeRef });
  },
  setFaqRef: (faqRef: React.RefObject<HTMLLIElement>) => {
    set({ faqRef });
  },
}));
