import { CalendarCog, Code, Headset, PaintRoller } from 'lucide-react';

export const benefits = {
  title: 'ce qui rend nailam unique',
  subTitle: 'chez nailam',
  descritoion:
    'Nous transformons vos idées en expériences digitales uniques, conçues pour vous démarquer et réussir en ligne.',
  benefits: [
    {
      title: 'Pure Code',
      description:
        'Nous réalisons des sites web en pur code, garantissant des performances optimales, une sécurité renforcée et une personnalisation totale.',
      icon: <Code color="black" />,
    },
    {
      title: 'Support Technique Dédié',
      description:
        "Profitez d'un support technique réactif et personnalisé, disponible pour vous accompagner à chaque étape de votre projet et au-delà.",
      icon: <Headset color="black" />,
    },
    {
      title: 'Design Personnalisé',
      description:
        "Chaque projet est conçu sur-mesure, avec une attention particulière portée à l'esthétique et à l'ergonomie, afin de refléter parfaitement l'identité de votre entreprise.",
      icon: <PaintRoller color="black" />,
    },
    {
      title: 'Mises à jour à Vie',
      description:
        'Bénéficiez de mises à jour régulières pour garantir la pérennité et la sécurité de votre site web, sans frais supplémentaires.',
      icon: <CalendarCog color="black" />,
    },
  ],
};
