import { Home } from '../../pages/Home';
import { Contact } from '../../pages/Contact';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { Layout } from '../../components/ui/Layout';
import { MentionsLegales } from '../../pages/MentionsLegales';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      // {
      //   path: 'devis-gratuit',
      //   element: <Estimate />,
      // },
      // {
      //   path: 'blog',
      //   children: [
      //     {
      //       path: '',
      //       element: <Blog />,
      //     },
      //     {
      //       path: ':slug',
      //       element: <BlogPost />,
      //     },
      //   ],
      // },
      // {
      //   path: 'realisations',
      //   element: <Achievements />,
      // },
      {
        path: 'mentions-légales',
        element: <MentionsLegales />,
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
]);
