import { FC } from 'react';
import { useDocumentTitle } from 'usehooks-ts';

export const MentionsLegales: FC = () => {
  useDocumentTitle('nailam - Mentions légales');

  return (
    <main className="xl:px-40 lg:px-20 sm:px-10 px-5 flex justify-center mb-36">
      <section className="w-[1500px]">
        <h1 className="mt-24 uppercase font-bold md:text-5xl text-3xl overflow-y-hidden">
          MENTIONS LÉGALES
        </h1>
        <p className="mt-8 px-40 text-xl">
          Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
          pour la confiance en l'économie numérique, il est précisé aux
          utilisateurs du site nailam l'identité des différents intervenants
          dans le cadre de sa réalisation et de son suivi.
        </p>
        <h2 className="uppercase font-bold md:text-4xl text-3xl leading-tight mb-4 overflow-y-hidden mt-14">
          Edition du site
        </h2>
        <p className="px-40 text-xl">
          Le présent site, accessible à l’URL www.nailam.fr (le « Site »), est
          édité par : <br />
          nailam, société au capital de 2 euros, inscrite au R.C.S. de NANTERRE
          sous le numéro 929 319 507 R.C.S. Nanterre, dont le siège social est
          situé au 7 rue descartes 92230 Gennevilliers, représenté(e) par Ramzi
          Naili dûment habilité(e).
          <br />
          <br /> Le numéro individuel TVA de l’éditeur est : FR53929319507.
        </p>
        <h2 className="uppercase font-bold md:text-4xl text-3xl leading-tight mb-4 overflow-y-hidden mt-14">
          Hébergement
        </h2>
        <p className="px-40 text-xl">
          Le Site est hébergé par la société Hostinger International LTD, situé
          , (contact téléphonique ou email : https://www.hostinger.fr/contact).
        </p>
        <br />
        <h2 className="uppercase font-bold md:text-4xl text-3xl leading-tight mb-4 overflow-y-hidden mt-14">
          Directeur de publication
        </h2>
        <p className="px-40 text-xl">
          Le Directeur de la publication du Site est Ramzi Naili.
        </p>
        <br />
        <h2 className="uppercase font-bold md:text-4xl text-3xl leading-tight mb-4 overflow-y-hidden mt-14">
          Nous contacter
        </h2>
        <p className="px-40 text-xl">
          Par téléphone : +33782437780
          <br /> Par email : contact@nailam.fr <br />
          Par courrier : 7 rue Descartes 92230 Gennevilliers
        </p>
      </section>
    </main>
  );
};
