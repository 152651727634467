import { Mail, Phone } from 'lucide-react';

export const contact = {
  info: {
    title: 'nos informations',
    infos: [
      {
        icon: <Mail strokeWidth={1} size={24} color="black" />,
        title: 'notre email',
        content: 'contact@nailam.fr',
      },
      {
        icon: <Phone strokeWidth={1} size={24} color="black" />,
        title: 'téléphone',
        content: '06 24 09 48 70',
      },
    ],
  },
  form: {
    title: 'Notre equipe est toujours a l’ecoute',
    description:
      'Laborum nam aliquam fugit quae omnis doloribus porro soluta necessitatibus blanditiis repellat aut, illo, accusamus unde voluptates ratione placeat animi suscipit voluptatem?',
  },
};
