import { FC, useEffect, useRef } from 'react';
import { benefits } from '../assets/content/Benefits';
import { ButtonContact } from './ui/ButtonContact';

export const Benefits: FC = () => {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        entries[0].target.classList.add('animate-fadeIn');
        observer.unobserve(ref.current!);
      }
    });
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return (
    <section
      className="bg-[#0B0B0B] text-white overflow-x-hidden opacity-0 transition-all duration-[1500ms] ease-in-out"
      ref={ref}
    >
      <span className="border-b-[100px] border-b-[#0b0b0b] border-l-[100vw] border-l-white block" />
      <div className="flex justify-center xl:px-40 lg:px-20 sm:px-10 px-5">
        <div className=" max-w-[1500px]">
          <h2 className="uppercase font-bold md:text-4xl text-3xl leading-tight overflow-y-hidden my-16">
            {benefits.title}
          </h2>
          <ul className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-4 gap-5 pb-12 ">
            {benefits.benefits.map((benefit) => (
              <li
                key={benefit.title}
                className="border border-white rounded-xl px-4 py-7 bg-[#101010] flex flex-col items-center"
              >
                <div className="flex justify-center items-center w-fit rounded-full p-3 bg-white mb-5">
                  {benefit.icon}
                </div>
                <h3 className="uppercase font-medium text-l mb-3">
                  {benefit.title}
                </h3>
                <p className="font-light text-[#9f9f9f] text-center">
                  {benefit.description}
                </p>
              </li>
            ))}
          </ul>
          <div className="lg:flex items-center justify-between pb-24">
            <div className="max-lg:mb-5">
              <h3 className="uppercase font-bold text-2xl leading-tight mb-5">
                {benefits.subTitle}
              </h3>
              <p className="w-full lg:w-[570px]">{benefits.descritoion}</p>
            </div>
            <ButtonContact content="Commencer maintenant" isDark={false} />
          </div>
        </div>
      </div>
    </section>
  );
};
